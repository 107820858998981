/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PhotoRequest,
    PhotoRequestFromJSON,
    PhotoRequestFromJSONTyped,
    PhotoRequestToJSON,
} from './PhotoRequest';
import {
    PhotoRequestDetailedAllOf,
    PhotoRequestDetailedAllOfFromJSON,
    PhotoRequestDetailedAllOfFromJSONTyped,
    PhotoRequestDetailedAllOfToJSON,
} from './PhotoRequestDetailedAllOf';
import {
    PhotoRequestStatus,
    PhotoRequestStatusFromJSON,
    PhotoRequestStatusFromJSONTyped,
    PhotoRequestStatusToJSON,
} from './PhotoRequestStatus';
import {
    ShootingType,
    ShootingTypeFromJSON,
    ShootingTypeFromJSONTyped,
    ShootingTypeToJSON,
} from './ShootingType';

/**
 * 
 * @export
 * @interface PhotoRequestDetailed
 */
export interface PhotoRequestDetailed {
    /**
     * Идентификатор заявки на фото студию
     * @type {number}
     * @memberof PhotoRequestDetailed
     */
    id: number;
    /**
     * Идентификатор родительской заявки на фото студию
     * @type {number}
     * @memberof PhotoRequestDetailed
     */
    parentId?: number;
    /**
     * 
     * @type {PhotoRequestStatus}
     * @memberof PhotoRequestDetailed
     */
    status: PhotoRequestStatus;
    /**
     * 
     * @type {Array<ShootingType>}
     * @memberof PhotoRequestDetailed
     */
    shootingTypes: Array<ShootingType>;
    /**
     * Количество позиций на фото в заявке
     * @type {number}
     * @memberof PhotoRequestDetailed
     */
    itemCount: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestDetailed
     */
    photoBoxID: number | null;
    /**
     * Дата создания заявки
     * @type {number}
     * @memberof PhotoRequestDetailed
     */
    created: number;
    /**
     * Дата последнего обновления
     * @type {number}
     * @memberof PhotoRequestDetailed
     */
    updated: number;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestDetailed
     */
    producerID: number | null;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestDetailed
     */
    photographerID: number | null;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestDetailed
     */
    retoucherID: number | null;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestDetailed
     */
    photoModelID: number | null;
    /**
     * 
     * @type {string}
     * @memberof PhotoRequestDetailed
     */
    photoLink: string;
    /**
     * 
     * @type {string}
     * @memberof PhotoRequestDetailed
     */
    photoLinkBefore: string;
    /**
     * 
     * @type {string}
     * @memberof PhotoRequestDetailed
     */
    photoLinkAfter: string;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestDetailed
     */
    invoiceID: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PhotoRequestDetailed
     */
    assistantIDs: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PhotoRequestDetailed
     */
    visagisteIDs: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof PhotoRequestDetailed
     */
    issuingEditorID?: number;
}

export function PhotoRequestDetailedFromJSON(json: any): PhotoRequestDetailed {
    return PhotoRequestDetailedFromJSONTyped(json, false);
}

export function PhotoRequestDetailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PhotoRequestDetailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'status': PhotoRequestStatusFromJSON(json['status']),
        'shootingTypes': ((json['shootingTypes'] as Array<any>).map(ShootingTypeFromJSON)),
        'itemCount': json['itemCount'],
        'photoBoxID': json['photoBoxID'],
        'created': json['created'],
        'updated': json['updated'],
        'producerID': json['producerID'],
        'photographerID': json['photographerID'],
        'retoucherID': json['retoucherID'],
        'photoModelID': json['photoModelID'],
        'photoLink': json['photoLink'],
        'photoLinkBefore': json['photoLinkBefore'],
        'photoLinkAfter': json['photoLinkAfter'],
        'invoiceID': json['invoiceID'],
        'assistantIDs': json['assistantIDs'],
        'visagisteIDs': json['visagisteIDs'],
        'issuingEditorID': !exists(json, 'issuingEditorID') ? undefined : json['issuingEditorID'],
    };
}

export function PhotoRequestDetailedToJSON(value?: PhotoRequestDetailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentId': value.parentId,
        'status': PhotoRequestStatusToJSON(value.status),
        'shootingTypes': ((value.shootingTypes as Array<any>).map(ShootingTypeToJSON)),
        'itemCount': value.itemCount,
        'photoBoxID': value.photoBoxID,
        'created': value.created,
        'updated': value.updated,
        'producerID': value.producerID,
        'photographerID': value.photographerID,
        'retoucherID': value.retoucherID,
        'photoModelID': value.photoModelID,
        'photoLink': value.photoLink,
        'photoLinkBefore': value.photoLinkBefore,
        'photoLinkAfter': value.photoLinkAfter,
        'invoiceID': value.invoiceID,
        'assistantIDs': value.assistantIDs,
        'visagisteIDs': value.visagisteIDs,
        'issuingEditorID': value.issuingEditorID,
    };
}

