import { computed, onMounted, ref } from 'vue';

import { EAccountRole, wmsApi, WmsWorker } from '@/api';
import { uniq } from '@/utils/collection';

export const useFetchWorkers = (roles: EAccountRole[]) => {
  const allActiveWorkers = ref<WmsWorker[]>([]);
  const loadingWorkers = ref(false);

  onMounted(async () => {
    loadingWorkers.value = true;
    const allWorkers = await getWorkers(roles);

    allActiveWorkers.value = allWorkers.filter((worker) => worker.active);
    loadingWorkers.value = false;
  });

  const getWorkers = async (
    roles: EAccountRole[],
    page = 0,
    result: WmsWorker[] = [],
  ): Promise<WmsWorker[]> => {
    const { content, totalPages } = await wmsApi
      .post('account/workers', {
        searchParams: {
          isFired: true,
          size: 2000,
          page,
        },
        json: roles.map((r) => ({ roleName: r })),
      })
      .json<any>();

    const combinedResult = uniq([...result, ...content]);
    const nextPage = page + 1;

    if (nextPage < totalPages) {
      return await getWorkers(roles, nextPage, combinedResult);
    }

    return combinedResult;
  };

  const retouchers = computed(() =>
    allActiveWorkers.value
      .filter(hasRoleOrIsSelected(EAccountRole.PHOTOSTUDIO_RETOUCHER))
      .sort(byFirstName),
  );

  const hasRoleOrIsSelected =
    (role: EAccountRole) =>
    (worker: WmsWorker): boolean =>
      worker.roleDto.name === role;

  const byFirstName = (a: WmsWorker, b: WmsWorker): number =>
    a.firstName.localeCompare(b.firstName);

  return {
    allActiveWorkers,
    retouchers,
    loadingWorkers,
  };
};
