/* tslint:disable */
/* eslint-disable */
/**
 * Erp acceptance system
 * todo description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Ключи, которые есть у SKU, но не перечислены, будут УДАЛЕНЫ!!!
 * @export
 * @interface UpdateMedia
 */
export interface UpdateMedia {
    /**
     * Пути фото в хранилище для обновления порядка. ПОРЯДОК ВАЖЕН!
     * @type {Array<string>}
     * @memberof UpdateMedia
     */
    updateStudioImagePaths?: Array<string>;
    /**
     * Ключи фото для обновления порядка. ПОРЯДОК ВАЖЕН!
     * @type {Array<string>}
     * @memberof UpdateMedia
     */
    updateOrderImageKeys?: Array<string>;
}

export function UpdateMediaFromJSON(json: any): UpdateMedia {
    return UpdateMediaFromJSONTyped(json, false);
}

export function UpdateMediaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateMedia {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updateStudioImagePaths': !exists(json, 'updateStudioImagePaths') ? undefined : json['updateStudioImagePaths'],
        'updateOrderImageKeys': !exists(json, 'updateOrderImageKeys') ? undefined : json['updateOrderImageKeys'],
    };
}

export function UpdateMediaToJSON(value?: UpdateMedia | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updateStudioImagePaths': value.updateStudioImagePaths,
        'updateOrderImageKeys': value.updateOrderImageKeys,
    };
}

