import { PhotoRequestItemReviewStatus, PhotoRequestStatus } from '@/api';

export const photoRequestStatusColor: Record<PhotoRequestStatus, string> = {
  [PhotoRequestStatus.Unspecified]: 'PureYellow',
  [PhotoRequestStatus.Draft]: 'LightGray',
  [PhotoRequestStatus.Created]: 'PureYellow',
  [PhotoRequestStatus.OnPhotoShoot]: 'PureOrange',
  [PhotoRequestStatus.OnPhotoShootPanoramic]: 'PureOrange',
  [PhotoRequestStatus.PhotoLoading]: 'PureBlue',
  [PhotoRequestStatus.Photographed]: 'PureYellow',
  [PhotoRequestStatus.ReadyToRetouch]: 'PureYellow',
  [PhotoRequestStatus.OnRetoushing]: 'PureYellow',
  [PhotoRequestStatus.Retouched]: 'PureYellow',
  [PhotoRequestStatus.InReview]: 'PureYellow',
  [PhotoRequestStatus.Reviewed]: 'MediumGreen',
  [PhotoRequestStatus.Done]: 'PureGreen',
  [PhotoRequestStatus.PhotoLoadingError]: 'PureOrange',
};

export const reviewStatusColor: Record<PhotoRequestItemReviewStatus, string> = {
  [PhotoRequestItemReviewStatus.Accepted]: '#E1FAF4',
  [PhotoRequestItemReviewStatus.RetouchingDefect]: '#FFD591',
  [PhotoRequestItemReviewStatus.PhotoDefect]: '#FFA39E',
  [PhotoRequestItemReviewStatus.NotChecked]: '#91D5FF',
  [PhotoRequestItemReviewStatus.Unspecified]: '#91D5FF',
  [PhotoRequestItemReviewStatus.AutoRetouchingDefect]: '#FFD591',
};
