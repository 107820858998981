import { mapFactory } from 'parakeet-mapper';

export enum EAccountRole {
  ADMIN = 'admin',
  PHOTOSTUDIO_ADMIN = 'photostudio_admin',
  PHOTOSTUDIO_PHOTOGRAPHER = 'photostudio_photographer',
  PHOTOSTUDIO_TURNOVER_WORKER = 'photostudio_turnover_worker',
  PHOTOSTUDIO_PRODUCER = 'photostudio_producer',
  PHOTOSTUDIO_RETOUCHER = 'photostudio_retoucher',
  ISSUING_EDITOR = 'issuing_editor',
  PHOTOSTUDIO_VISAGISTE = 'photostudio_visagiste',
  PHOTOSTUDIO_ASSISTANT = 'photostudio_assistant',
  PHOTOSTUDIO_SUPPORT_SPECIALIST = 'photostudio_support_specialist',
  WAREHOUSE_ADMIN = 'warehouse_admin',
  WAREHOUSE_MANAGEMENT = 'warehouse_management',
  DEVELOPER = 'developer',
  OBSERVER = 'observer',
}

export interface IServerAuthUserInfo {
  firstName: string;
  lastName: string;
  role: EAccountRole;
  id: number;
  zoneId: number;
  patronymic?: string;
}

export interface IAuthUserInfo {
  firstName: string;
  lastName: string;
  role: EAccountRole;
  id: number;
  zoneId: number;
  patronymic?: string;
}

export const ConvertAuthUserInfo = mapFactory<
  IServerAuthUserInfo,
  IAuthUserInfo
>({
  firstName: true,
  lastName: true,
  role: true,
  id: true,
  zoneId: true,
});
