import { useLocalStorage } from '@vueuse/core';
import flagsmith from 'flagsmith';
import { computed, reactive, readonly, toRefs } from 'vue';

import { authApi, EAccountRole, IAuthAccountDto } from '@/api';
import { AppRoutes, baseRoute } from '@/router/data';
import { fullName } from '@/utils/name';

const AUTH_KEY = 'auth';

const cachedAccount = useLocalStorage<IAuthAccountDto | null>(AUTH_KEY, null, {
  serializer: {
    read: JSON.parse,
    write: JSON.stringify,
  },
});

const state = reactive({
  account: cachedAccount,
  error: '',
});

const cacheAccount = (account: IAuthAccountDto) => {
  cachedAccount.value = account;
};

const getCachedAccount = (): IAuthAccountDto | null => {
  const account = localStorage.getItem(AUTH_KEY);

  if (!account) return null;

  try {
    return JSON.parse(account);
  } catch {
    return null;
  }
};

const removeCachedAccount = () => {
  cachedAccount.value = null;
};

export const isAuthorized = (role: string | undefined) =>
  Object.values(EAccountRole).includes(role as EAccountRole);

export const useAuth = () => {
  const isAuthenticated = computed(() => state.account !== null);
  const accountId = computed(() => state.account?.userInfo.id);
  const token = computed(() => state.account?.accessToken);
  const rtoken = computed(() => state.account?.refreshToken);

  const rolesRights = {
    canMarkDefects: [EAccountRole.PHOTOSTUDIO_TURNOVER_WORKER],
    canRemoveItem: [
      EAccountRole.PHOTOSTUDIO_PRODUCER,
      EAccountRole.PHOTOSTUDIO_TURNOVER_WORKER,
    ],
    canReviewPhoto: [
      EAccountRole.PHOTOSTUDIO_ADMIN,
      EAccountRole.ISSUING_EDITOR,
    ],
    canEditBoxes: Object.values(EAccountRole).filter(
      (role) => role !== EAccountRole.PHOTOSTUDIO_SUPPORT_SPECIALIST,
    ),
    canEditRequests: Object.values(EAccountRole).filter(
      (role) => role !== EAccountRole.PHOTOSTUDIO_SUPPORT_SPECIALIST,
    ),
    canEditInvoices: Object.values(EAccountRole).filter(
      (role) => role !== EAccountRole.PHOTOSTUDIO_SUPPORT_SPECIALIST,
    ),
    canChangeRetoucherRequest: [EAccountRole.PHOTOSTUDIO_PRODUCER],
    canPrintSkuBarcode: Object.values(EAccountRole).filter(
      (role) => role !== EAccountRole.PHOTOSTUDIO_SUPPORT_SPECIALIST,
    ),
  } satisfies Record<string, EAccountRole[]>;

  const loginUrl = new URL(
    `${baseRoute}${AppRoutes.login.path}`,
    window.location.origin,
  );

  const role = computed(() => state.account?.userInfo.role);

  const isPhotostudioAdmin = computed(() => {
    if (!role.value) return false;

    const roles = [EAccountRole.PHOTOSTUDIO_ADMIN, EAccountRole.ADMIN];

    return roles.includes(role.value);
  });

  const isRetoucher = computed(() => {
    if (!role.value) return false;

    return role.value === EAccountRole.PHOTOSTUDIO_RETOUCHER;
  });

  const isProducer = computed(() => {
    if (!role.value) return false;

    return role.value === EAccountRole.PHOTOSTUDIO_PRODUCER;
  });

  const isSupport = computed(() => {
    if (!role.value) return false;

    return role.value === EAccountRole.PHOTOSTUDIO_SUPPORT_SPECIALIST;
  });

  const isPhotographer = computed(() => {
    if (!role.value) return false;

    return [EAccountRole.PHOTOSTUDIO_PHOTOGRAPHER].includes(role.value);
  });

  const getRoleRights = (scope: keyof typeof rolesRights) =>
    computed(() => {
      if (!role.value) return false;

      const roles = rolesRights[scope] as EAccountRole[];

      return isPhotostudioAdmin.value || roles.includes(role.value);
    });

  return {
    isAuthenticated,
    token: token.value,
    rtoken: rtoken.value,
    isAuthorized: computed(() => isAuthorized(role.value)),
    loginUrl,
    role,
    isPhotostudioAdmin,
    isRetoucher,
    isSupport,
    isProducer,
    canReviewPhoto: getRoleRights('canReviewPhoto'),
    canMarkDefects: getRoleRights('canMarkDefects'),
    canRemoveItem: getRoleRights('canRemoveItem'),
    canEditBoxes: getRoleRights('canEditBoxes'),
    canEditRequests: getRoleRights('canEditRequests'),
    canEditInvoices: getRoleRights('canEditInvoices'),
    canChangeRetoucherRequest: getRoleRights('canChangeRetoucherRequest'),
    canPrintSkuBarcode: getRoleRights('canPrintSkuBarcode'),
    isPhotographer,
    accountId,
    ...toRefs(readonly(state)),
  };
};

const identifyFlagsmith = async ({ userInfo }: IAuthAccountDto) => {
  const id = `[${userInfo.id}] ${fullName(userInfo)}`;

  await flagsmith.identify(id, { role: userInfo.role });
};

class UnauthorizedError extends Error {
  constructor() {
    super('Unauthorized');
  }
}

export const isUnauthorizedError = (error: unknown) =>
  error instanceof UnauthorizedError;

export const useAuthMethods = () => {
  const rehydrateAccount = async () => {
    state.account = getCachedAccount();

    if (state.account) {
      await identifyFlagsmith(state.account);
    }

    return state.account;
  };

  const login = async (
    username: string,
    password: string,
  ): Promise<IAuthAccountDto> => {
    const account = await authApi.login({
      username,
      password,
      grant_type: 'password',
    });

    if (!isAuthorized(account.userInfo.role)) {
      throw new UnauthorizedError();
    }

    state.account = account;

    if (state.account) {
      cacheAccount(state.account);
    }

    return state.account;
  };

  const refreshToken = async () => {
    if (state.account) {
      state.account = await authApi.refreshToken(state.account.refreshToken);
      await identifyFlagsmith(state.account);
      cacheAccount(state.account);

      return state.account;
    }
  };

  const logOut = () => {
    removeCachedAccount();
    state.account = null;
  };

  return { login, refreshToken, rehydrateAccount, logOut };
};
