<template>
  <BaseFilterWrapper>
    <template #header>
      <span v-if="firstSelectedWorker && !loadingWorkers" class="selected">
        <span class="chip shrink-0">
          {{ firstSelectedWorker.lastName }}
          {{ firstSelectedWorker.firstName[0] }}.
          {{
            firstSelectedWorker.patronymic
              ? firstSelectedWorker.patronymic[0]
              : null
          }}
        </span>
        <span v-if="modelValue.length > 1" class="count">
          +{{ modelValue.slice(1).length }}
        </span>
      </span>
      <span v-else>
        {{ $t('roles.retoucher') }}
      </span>
    </template>
    <template #content>
      <UiMenu
        :selected-keys="modelValue"
        multiple
        class="menu"
        @update:selected-keys="$emit('update:modelValue', $event)"
      >
        <UiMenuItem v-for="worker in availableRetouchers" :key="worker.id">
          <span>
            {{ worker.lastName }} {{ worker.firstName[0] }}.
            {{ worker.patronymic ? worker.patronymic[0] : null }}
          </span>
          <CheckOutlined class="check" />
        </UiMenuItem>
      </UiMenu>
    </template>
  </BaseFilterWrapper>
</template>

<script setup lang="ts">
import { CheckOutlined, DownOutlined } from '@ant-design/icons-vue';
import { computed } from 'vue';

import { EAccountRole, WmsWorker } from '@/api';
import { useFetchWorkers } from '@/composables/useFetchWorkers';
import { useAuth } from '@/features/Auth';
import { UiButton, UiMenu, UiMenuItem, UiPopover } from '@/ui';
import BaseFilterWrapper from '@/ui/filter/base-filter-wrapper.vue';

interface Props {
  modelValue: number[];
}

interface Emits {
  (e: 'update:modelValue', v: string[]): void;
}

const props = defineProps<Props>();

defineEmits<Emits>();

const { isPhotostudioAdmin, isRetoucher, accountId } = useAuth();

const { retouchers, loadingWorkers } = useFetchWorkers([
  EAccountRole.PHOTOSTUDIO_RETOUCHER,
]);

const availableRetouchers = computed<WmsWorker[]>(() => {
  if (isPhotostudioAdmin.value) {
    return retouchers.value;
  }

  if (isRetoucher.value) {
    return retouchers.value.filter((worker) => worker.id === accountId.value);
  }

  return [];
});

const firstSelectedWorker = computed<WmsWorker | null>(() => {
  return (
    availableRetouchers.value.find(
      (worker) => worker.id === props.modelValue[0],
    ) || null
  );
});
</script>

<style scoped lang="stylus">
.button
  padding 8px 15px
  justify-content space-between
  font-size 14px
  color gray-10

  .selected
    display flex
    align-items center
    gap 10px

  .count
    border-radius 50%
    font-weight 500
    font-size 10px
    width 24px
    line-height 24px
    text-align center
    letter-spacing 0.01
    height 24px
    background-color gray-4
    color #313235

.icon
  width 14px
  height 14px

.menu
  border-inline-end 0 !important
  max-height calc(100vh - 200px)
  overflow-y auto

  :deep()
    .ant-menu-item

      height auto
      margin 0
      padding 8px 12px
      line-height 1.5
      color gray-10

    .ant-menu-title-content
      display flex
      align-items center
      gap 10px

    .check
      display none

    &.ant-menu-item-selected
      background-color gray-2

      .check
        display inline-block
        color blue-6
</style>
